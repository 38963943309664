import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

import * as _jsonBuffer2 from "json-buffer";

var _jsonBuffer = "default" in _jsonBuffer2 ? _jsonBuffer2.default : _jsonBuffer2;

import * as _zlib2 from "zlib";

var _zlib = "default" in _zlib2 ? _zlib2.default : _zlib2;

import _mergeOptions from "./merge-options";
var exports = {};
const {
  promisify
} = _util;
const JSONB = _jsonBuffer;
const zlib = _zlib;
const mergeOptions = _mergeOptions;
const compress = promisify(zlib.brotliCompress);
const decompress = promisify(zlib.brotliDecompress);

const identity = val => val;

const createCompress = ({
  enable = true,
  serialize = JSONB.stringify,
  deserialize = JSONB.parse,
  compressOptions,
  decompressOptions
} = {}) => {
  if (!enable) {
    return {
      serialize,
      deserialize,
      decompress: identity,
      compress: identity
    };
  }

  return {
    serialize,
    deserialize,
    compress: async (data, options = {}) => {
      if (data === undefined) return data;
      const serializedData = serialize(data);
      return compress(serializedData, mergeOptions(compressOptions, options));
    },
    decompress: async (data, options = {}) => {
      if (data === undefined) return data;
      return deserialize(await decompress(data, mergeOptions(decompressOptions, options)));
    }
  };
};

exports = createCompress;
exports.stringify = JSONB.stringify;
exports.parse = JSONB.parse;
export default exports;
export const stringify = exports.stringify,
      parse = exports.parse;