var exports = {};

exports = (defaultOptions = {}, options = {}) => {
  const params = { ...(defaultOptions.params || {}),
    ...(options.params || {})
  };
  return { ...defaultOptions,
    ...options,
    ...(Object.keys(params).length ? {
      params
    } : {})
  };
};

export default exports;